import { PageUrlCell } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/contracts/Component/PageUrlCell/index';
import React from 'react';
import { Checkbox } from 'components';
import { TrackingUrlType } from 'pages/Merchants/FintelCheck/FintelCheckPublisherSearch/enums';

import { CellStyled, CellWrapper } from '../styles';

export const columns = (
  isReadOnly: boolean,
  handleCheckBox: (checked: boolean, row: TrackingUrlType) => void
): TableColumn[] => [
    {
      dataField: 'checked',
      text: '',
      width: '4vw',
      formatter: (val, row) => (
        <CellStyled>
          <Checkbox key={val} checked={val} onChange={(checked) => handleCheckBox(checked, row)} disabled={isReadOnly} />
        </CellStyled>
      ),
    },
    {
      dataField: 'url',
      text: 'Url',
      width: '40vw',
      sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
      formatter: (val) => <PageUrlCell val={val} />,
    },
    {
      dataField: 'status',
      text: 'Status',
      width: '10vw',
      sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
      formatter: (val) => <CellWrapper>{val}</CellWrapper>,
    },
    {
      dataField: 'lastActive',
      text: 'Last Active',
      width: '10vw',
      sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
      formatter: (val) => <CellWrapper>{val}</CellWrapper>,
    },
    {
      dataField: 'checkType',
      text: 'Check Type',
      width: '15vw',
      sortFunction: (a: any, b: any) => a.name.localCompare(b.name),
      formatter: (val) => <CellWrapper>{val}</CellWrapper>,
    },
  ];
