// eslint-disable-next-line import/no-extraneous-dependencies
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export const PUBLISHER_SEARCH = {
  BUTTONS: {
    BACK: 'Back',
    UPDATE: 'Update Custom List',
    CLEAR: 'Clear Form',
  },
  FILTER: 'Filters',
};

export const URL_STATUS = [
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Deleted', value: 'DELETED' },
];

export type TrackingUrlType = {
  id: number;
  url: string;
  status: string;
  lastActive: string;
  checked: boolean;
  edit: IconDefinition;
  delete: IconDefinition;
  category: string;
  checkType: string;
};

export const URLS_LIST = {
  BUTTONS: {
    ADD: 'Add URL',
    DOWNLOAD: 'Download CSV',
    REMOVE: 'Remove URLs',
    CLEAR: 'Clear Form',
    UPDATE: 'Update URLs',
  },
  PLACEHOLDERS: {
    INPUT: 'https://www.fintelconnect.com',
  },
  MODAL: {
    TITLE: {
      ADD: 'Add URL',
      EDIT: 'Edit URL',
      REMOVE: 'Remove URL',
    },
    BUTTON: {
      ADD: 'Add',
      EDIT: 'Save',
      REMOVE: 'Remove',
      CANCEL: 'Cancel',
    },
    TYPES: {
      ADD: 'ADD',
      EDIT: 'EDIT',
      REMOVE: 'REMOVE',
      REMOVE_MANY: 'REMOVE_MANY',
    },
  },
  ERROR: {
    DOMAIN_COLLISION: 'URL already in Tracking URL list',
    GET_DATA_ERROR: 'Could not load Tracking Url list. Please try again later.',
    MISSING_CATEGORY: 'Please select a category',
    MISSING_CHECK_TYPE: 'Please select a check type',
    UPDATE_CHECK_TYPE: 'Could not update url(s). Please try again later.',
  },
  SUCCESS: 'URL(s) successfully updated',
};
