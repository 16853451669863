import { useLazyQuery } from '@apollo/client';
import { useUserInfo } from 'hooks';
import { RuleReportType } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/graphql/queries/getMonitoringReport';
import { processDataToCSVString } from 'pages/Merchants/FintelCheck/FintelCheckDetails/utils/processDataForCSV';
import { useFintelCheckContext } from 'pages/Merchants/FintelCheck/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dateFormatter, csvGenerator } from 'utils';

import { columns } from '../contracts/columns';
import { GET_HISTORY_REPORT_RESULTS } from '../graphql/queries/getHistoryReport';

type HistoryDataType = {
  id: string;
  reviewStatus: string;
  previousReviewStatus: string;
  createDatetime: string | null;
  ruleStatus: string;
  urlSearch: string;
  ruleName: string;
  ruleGroup: string;
};

export const useHistoryReport = (handleOnScreenshotClick: (val: any) => void, isMonitoringReport: boolean) => {
  const { contextState, contextDispatchHandler: setContextState } = useFintelCheckContext();
  const { hookWhoAmI } = useUserInfo();
  const navigate = useNavigate();

  // Filters
  const [ruleName, setRuleName] = useState<string>('');
  const [pageUrl, setPageUrl] = useState<string>('');
  const [merchantName, setMerchantName] = useState<string>('');
  const [merchantId, setMerchantId] = useState<string>('');

  const [records, setRecords] = useState({ label: '10', value: '10' });

  // Data
  const [tableData, setTableData] = useState<HistoryDataType[]>([]);
  const [dataGenerated, setDataGenerated] = useState(false);
  const [generateClicked, setGenerateClicked] = useState(false);
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [getHistoryReport, { loading: isHistoryReportLoading }] = useLazyQuery(GET_HISTORY_REPORT_RESULTS);

  // Table & Pagination
  const [tableColumns, setTableColumns] = useState<TableColumn[]>(columns());
  const [tableKey, setTableKey] = useState<number>(123456); // Used to force table to rerender from start

  const [sortColumn, setSortColumn] = useState<TableSortColumn>({ column: 'createDatetime', direction: 'desc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [refetchTable, setRefetchTable] = useState<boolean>(false);

  const loadHistory = (row: RuleReportType): void => {
    setRuleName(row.ruleName);
    setPageUrl(row.urlSearch);
    if (row.merchant) {
      setMerchantName(row.merchant?.split('-')[1] ?? '');
      setMerchantId(row.merchant?.split('-')[0] ?? '');
    }
    setDownloadClicked(false);
    setGenerateClicked(true);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const getHistoryReportData = async (
    limit: number,
    page?: null | number
  ): Promise<{
    historyReport: {
      historyReport: any[];
      totalPages: number;
      count: number;
    };
  }> => {
    try {
      const input = {
        merchantId: merchantId ?? hookWhoAmI.companyId ?? '0',
        ruleName,
        pageUrl,
        sortBy: sortColumn.column,
        sortDirection: sortColumn.direction ?? 'desc',
        limit,
        currentPage: page ?? currentPage,
        isMonitoringReport,
      };

      const { data } = await getHistoryReport({ variables: { input }, fetchPolicy: 'no-cache' });
      return data;
    } catch (error) {
      return {
        historyReport: {
          historyReport: [],
          totalPages: 0,
          count: 0,
        },
      };
    }
  };

  const getHistoryReportHandler = async (): Promise<void> => {
    const data = await getHistoryReportData(Number(records.value));
    setTableKey((last) => last + 1);
    if (data?.historyReport?.historyReport) {
      const formattedData: HistoryDataType[] = data.historyReport.historyReport.map((item: any) => ({
        id: item.id,
        reviewStatus: item.reviewStatus,
        previousReviewStatus: item.previousReviewStatus,
        createDatetime: item.createDatetime ? dateFormatter(new Date(item.createDatetime), '/') : null,
        ruleStatus: item.ruleStatus,
        urlSearch: item.pageUrl,
        ruleName: item.ruleName,
        ruleGroup: item.ruleGroup,
        productCategory: item.productCategory,
        productName: item.productName,
        publisher: item.publisher,
      }));

      setTableColumns(columns(handleOnScreenshotClick));
      setTableData(formattedData);
      setTotalPages(data.historyReport.totalPages);
      setTotalRecords(data.historyReport.count);
      setDataGenerated(true);
    } else {
      setTableColumns(columns());
      setTableData([]);
      setCurrentPage(1);
      setTotalPages(0);
      setTotalRecords(0);
    }
  };

  const generateHistoryReport = (): void => {
    setDownloadClicked(false);
    setGenerateClicked(true);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const downloadHistoryReportCSV = (): void => {
    setDownloadClicked(true);
    setGenerateClicked(false);
    setRefetchTable(true);
    setCurrentPage(1);
  };

  const downloadHistoryReportHandler = async (): Promise<void> => {
    const limit = 1000000; // FC-979: Limit to a large number to download all records
    const data = await getHistoryReportData(limit, 1);
    setDownloadClicked(false);
    setTableKey((last) => last + 1);
    if (data?.historyReport?.historyReport) {
      const formattedData = data.historyReport.historyReport.map((item: any) => ({
        id: item.id,
        reviewStatus: item.reviewStatus,
        previousReviewStatus: item.previousReviewStatus,
        createDatetime: item.createDatetime ? dateFormatter(new Date(item.createDatetime), '/') : null,
        ruleStatus: item.ruleStatus,
        urlSearch: item.pageUrl,
        ruleName: item.ruleName,
        ruleGroup: item.ruleGroup,
        productCategory: item.productCategory,
        productName: item.productName,
        publisher: item.publisher,
      }));

      const csvString = processDataToCSVString(formattedData, columns());
      csvGenerator(csvString, 'Fintel Check Monitoring Historical Report');

      generateHistoryReport();
    }
  };

  const setOnDragEndHandler = (result: any): void => {
    setTableColumns(result);
  };

  const handleSort = (dataField: string): void => {
    setSortColumn({ column: dataField, direction: sortColumn.direction === 'asc' ? 'desc' : 'asc' });
    setRefetchTable(true);
  };

  const handlePageChange = (value: any): void => {
    setCurrentPage(value);
    setRefetchTable(true);
  };

  if (generateClicked && refetchTable) {
    setRefetchTable(false);
    getHistoryReportHandler();
  }

  if (downloadClicked && refetchTable) {
    setRefetchTable(false);
    downloadHistoryReportHandler();
  }

  useEffect(() => {
    setCurrentPage(1);
    setRefetchTable(true);
  }, [records]);

  const resetPage = (): void => {
    setTableData([]);
    setTableColumns([]);
    setTotalPages(0);
    setTotalRecords(0);
    setCurrentPage(1);
  };

  return {
    tableData,
    tableColumns,
    tableKey,
    navigate,

    contextState,
    setContextState,

    sortColumn,
    handleSort,

    currentPage,
    totalPages,
    totalRecords,
    handlePageChange,

    generateHistoryReport,
    downloadHistoryReportCSV,
    dataGenerated,
    records,
    setRecords,

    ruleName,
    setRuleName,

    pageUrl,
    setPageUrl,

    setOnDragEndHandler,
    loadHistory,
    isHistoryReportLoading,

    resetPage,

    merchantName,
  };
};
