import { faFileDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AppWrapper, ErrorBanner, Modal, PageTitle, Spinner } from 'components';
import MonitoringDetailsModal from 'pages/Merchants/FintelCheck/Components/MonitoringDetailsModal';
import { RulesHistoryReport } from 'pages/Merchants/FintelCheck/Components/MonitoringHistoryReport';
import RulesSummaryDashboard from 'pages/Merchants/FintelCheck/Components/RulesSummaryDashboard';
import { RulesSummaryReport } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport';
import { SUMMARY } from 'pages/Merchants/FintelCheck/Components/RulesSummaryReport/enums';
import { useEvaluationRulesReport } from 'pages/Merchants/FintelCheck/EvaluationRulesReport/hooks/useEvaluationRulesReport';
import React from 'react';

import * as Styled from '../MonitoringRulesReport/styles';

type FintelCheckReportProps = {
  permissionsCodeList?: string[];
};

const EvaluationRulesReport = ({ permissionsCodeList = [] }: FintelCheckReportProps): JSX.Element => {
  const hook = useEvaluationRulesReport();

  return (
    <AppWrapper background permissionsCodeList={permissionsCodeList}>
      <ErrorBanner message="hook.errorMessage" isOpen={false} />
      <Styled.HeaderStyled>
        <PageTitle>{hook.displaySummary ? 'Evaluation Rules Report' : 'Evaluation Rules History Report'}</PageTitle>
      </Styled.HeaderStyled>
      <Styled.SectionWrapper>
        {hook.displaySummary ? (
          <>
            <RulesSummaryDashboard isMonitoringReport={false} isAdmin={false} />

            <RulesSummaryReport summaryHook={hook.summaryHook} isAdmin={false} hook={hook} isMonitoringReport={false} />
          </>
        ) : (
          <RulesHistoryReport historyHook={hook.historyHook} hook={hook} />
        )}

        <MonitoringDetailsModal hook={hook} />
        <Modal
          isOpen={hook.isScreenshotModalOpen}
          closeModalFunction={() => {
            hook.closeScreenshotModal();
          }}
        >
          {hook.getScreenshotImageLoading ? (
            <Spinner size="3rem" theme="quaternary" />
          ) : (
            <>
              <Styled.ScreenshotModalHeaderContainer>
                <Styled.ScreenshotModalHeaderItemLeft>Screenshot Image</Styled.ScreenshotModalHeaderItemLeft>
                <Styled.ScreenshotModalHeaderItemCenter>
                  <Styled.SimpleFlexWrapper>
                    <Styled.ButtonStyled
                      theme="tertiary"
                      onClick={() => hook.downloadScreenshot()}
                      width="160px"
                      disabled={hook.getScreenshotImageLoading}
                    >
                      <Styled.IconStyled icon={faFileDownload} />
                      {SUMMARY.DOWNLOAD_IMAGE}
                    </Styled.ButtonStyled>
                  </Styled.SimpleFlexWrapper>
                </Styled.ScreenshotModalHeaderItemCenter>
                <Styled.ScreenshotModalHeaderItemRight>
                  <Styled.DetailedViewCloseIcon icon={faTimes} onClick={() => hook.closeScreenshotModal()} />
                </Styled.ScreenshotModalHeaderItemRight>
              </Styled.ScreenshotModalHeaderContainer>
              <Styled.ScreenshotImage>
                {hook.screenshotImage ? (
                  <img src={`data:image/jpeg;base64,${hook.screenshotImage}`} alt="cover" />
                ) : (
                  <Styled.NoImage> No Image Found </Styled.NoImage>
                )}
              </Styled.ScreenshotImage>
            </>
          )}
        </Modal>
      </Styled.SectionWrapper>
    </AppWrapper>
  );
};

export default EvaluationRulesReport;
