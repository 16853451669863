import { MERCHANT_PREFIX, path, PUBLISHER_PREFIX, securityMap } from '../../../utils';

export const admin = [
  {
    id: 'dashboard',
    key: 'dashboard',
    href: path.dashboard.href,
    label: path.dashboard.name,
    icon: path.dashboard.icon,
  },
  {
    id: 'merchants',
    key: securityMap.admin.merchants,
    label: path.merchants.name,
    icon: path.merchants.icon,
    management: {
      href: path.merchantManagement.href,
      label: path.merchantManagement.name,
    },
    subSections: [
      {
        id: 'merchantsDashboard',
        key: securityMap.merchant.merchantDashboard,
        href: MERCHANT_PREFIX + path.dashboard.href,
        label: path.dashboard.name,
      },
      {
        id: 'merchantsAccount',
        key: securityMap.merchant.merchantAccount,
        label: path.account.name,
        subSections: [
          {
            id: 'merchantsAccountDetails',
            key: [
              securityMap.merchant.merchantAccountAccountDetails,
              securityMap.merchant.merchantAccountUserManagement,
            ],
            href: path.merchantManagementDetail.href,
            label: path.accountDetails.name,
          },
          {
            id: 'merchantsAccountProgramSettings',
            key: securityMap.merchant.merchantAccountProgramSettings,
            href: MERCHANT_PREFIX + path.programSettings.href,
            label: path.programSettings.name,
          },
          {
            id: 'merchantsAccountAccountBalance',
            key: securityMap.merchant.merchantAccountAccountBalance,
            href: MERCHANT_PREFIX + path.accountBalance.href,
            label: path.accountBalance.name,
          },
          {
            id: 'merchantsAccountProductCatalogue',
            key: securityMap.merchant.merchantAccountProductCatalogue,
            href: MERCHANT_PREFIX + path.productCatalog.href,
            label: path.productCatalog.name,
          },
          {
            id: 'merchantsAccountTrackingTransactionSettings',
            key: securityMap.merchant.merchantAccountTrackingTransactionSettings,
            href: MERCHANT_PREFIX + path.trackingSettings.href,
            label: path.trackingSettings.name,
          },
          {
            id: 'merchantsAccountPostbackDetails',
            key: securityMap.merchant.merchantPostbackDetails,
            href: MERCHANT_PREFIX + path.postbackDetails.href,
            label: `Merchant ${path.postbackDetails.name}`,
          },
        ],
      },
      {
        id: 'merchantsOverview',
        key: securityMap.merchant.merchantCommissions,
        href: MERCHANT_PREFIX + path.manageCommissions.href,
        label: path.manageCommissions.name,
      },
      {
        id: 'merchantsPublishers',
        key: securityMap.merchant.merchantPublishers,
        label: path.publishers.name,
        subSections: [
          {
            id: 'merchantsPublishersMembershipManagement',
            key: securityMap.merchant.merchantPublishersMembershipManagement,
            href: MERCHANT_PREFIX + path.membershipManagement.href,
            label: path.membershipManagement.name,
          },
          {
            id: 'merchantsPublishersPublisherInvitations',
            key: securityMap.merchant.merchantPublishersPublisherInvitations,
            href: MERCHANT_PREFIX + path.publisherInvitations.href,
            label: path.publisherInvitations.name,
          },
          {
            id: 'merchantsPublishersPublisherSearch',
            key: securityMap.merchant.merchantPublishersPublisherSearch,
            href: MERCHANT_PREFIX + path.publisherSearch.href,
            label: path.publisherSearch.name,
          },
          {
            id: 'merchantsPublishersPublisherGroup',
            key: securityMap.merchant.merchantPublishersPublisherGroup,
            href: MERCHANT_PREFIX + path.publisherGroups.href,
            label: path.publisherGroups.name,
          },
          {
            id: 'merchantsPublishersInternalPublisher',
            key: securityMap.merchant.merchantPublishersInternalPublisher,
            href: MERCHANT_PREFIX + path.internalPublisher.href,
            label: path.internalPublisher.name,
          },
        ],
      },
      {
        id: 'merchantsAds',
        key: securityMap.merchant.merchantAds,
        label: path.ads.name,
        subSections: [
          {
            id: 'merchantsAdsAdSearch',
            key: securityMap.merchant.merchantAdSearch,
            href: MERCHANT_PREFIX + path.adSearch.href,
            label: path.adSearch.name,
          },
          {
            id: 'merchantsAdsCampaignManagement',
            key: securityMap.merchant.merchantAdsCampaignManagement,
            href: MERCHANT_PREFIX + path.campaignManagement.href,
            label: path.campaignManagement.name,
          },
        ],
      },
      {
        id: 'merchantsReports',
        key: securityMap.merchant.merchantReports,
        label: path.reports.name,
        subSections: [
          {
            id: 'merchantsReportsPerformanceReport',
            key: securityMap.merchant.merchantReportsPerformanceReport,
            href: MERCHANT_PREFIX + path.performanceReport.href,
            label: path.performanceReport.name,
          },
          {
            id: 'merchantsReportsTransactionMaster',
            key: securityMap.merchant.merchantReportsTransactionMaster,
            href: MERCHANT_PREFIX + path.transactionMaster.href,
            label: path.transactionMaster.name,
          },
          {
            id: 'merchantsReportsPaymentReport',
            key: securityMap.merchant.merchantReportsPaymentReport,
            href: MERCHANT_PREFIX + path.paymentReport.href,
            label: path.paymentReport.name,
          },
          {
            id: 'merchantsReportsLinkManager',
            key: securityMap.merchant.merchantReportsLinkManager,
            href: MERCHANT_PREFIX + path.linkManager.href,
            label: path.linkManager.name,
          },
          {
            id: 'merchantsReportsNewSiteMonitoring',
            key: securityMap.merchant.merchantReportsNewSiteMonitoring,
            href: MERCHANT_PREFIX + path.newSiteMonitoring.href,
            label: path.newSiteMonitoring.name,
          },
        ],
      },
      {
        id: 'merchantsFintelCheck',
        key: securityMap.merchant.merchantFintelCheck,
        label: path.fintelChecks.name,
        subSections: [
          {
            id: 'merchantsFintelCheckReports',
            key: securityMap.merchant.merchantFintelCheck,
            label: 'Reports',
            subSections: [
              {
                id: 'merchantsFintelCheckReportsEvaluationRulesReport',
                key: securityMap.merchant.merchantFintelEvaluationRulesReport,
                href: MERCHANT_PREFIX + path.evaluationRulesReport.href,
                label: 'Evaluation Rules Report',
              },
              {
                id: 'merchantsFintelCheckReportsMonitoringReport',
                key: securityMap.merchant.merchantFintelMonitoringRulesReport,
                href: MERCHANT_PREFIX + path.monitoringRulesReport.href,
                label: 'Monitoring Report',
              },
              {
                id: 'merchantsFintelCheckReportsChangeMonitoring',
                key: securityMap.merchant.merchantFintelCheckChangeMonitoring,
                href: MERCHANT_PREFIX + path.fintelCheckChangeMonitoring.href,
                label: 'Page Change Report',
              },
              {
                id: 'merchantsFintelCheckReportsCheckReport',
                key: securityMap.merchant.merchantFintelCheckFintelCheckReport,
                href: MERCHANT_PREFIX + path.fintelCheckReport.href,
                label: 'Check Report (Legacy)',
              },
            ],
          },
          {
            id: 'merchantsFintelCheckRuleManager',
            key: securityMap.merchant.merchantFintelCheck,
            label: 'Rule Manager',
            subSections: [
              {
                id: 'merchantsFintelCheckRuleManagerRuleManager',
                key: securityMap.merchant.merchantFintelCheckRuleManager,
                href: MERCHANT_PREFIX + path.ruleManager.href,
                label: 'Rules',
              },
            ],
          },
          {
            id: 'merchantsFintelCheckSettings',
            key: securityMap.merchant.merchantFintelCheck,
            label: 'Settings',
            subSections: [
              {
                id: 'merchantsFintelCheckSettingsFintelCheckingSettings',
                key: securityMap.merchant.merchantFintelCheckFintelCheckingSettings,
                href: MERCHANT_PREFIX + path.fintelCheckSettings.href,
                label: path.fintelCheckSettings.name,
              },
              {
                id: 'merchantsFintelCheckSettingsManualUrls',
                key: securityMap.merchant.merchantFintelCheckManualUrls,
                href: MERCHANT_PREFIX + path.fintelCheckManualUrlsList.href,
                label: 'Manual URLs',
              },
              {
                id: 'merchantsFintelCheckSettingsPublisherUrls',
                key: securityMap.merchant.merchantFintelCheckPublisherList,
                href: MERCHANT_PREFIX + path.fintelCheckCustomPublisherList.href,
                label: 'Tracking URLs',
              },
              {
                id: 'merchantsFintelCheckSettingsBlacklistedUrls',
                key: securityMap.merchant.merchantFintelCheckBlacklist,
                href: MERCHANT_PREFIX + path.fintelCheckBlacklist.href,
                label: 'Blacklisted URLs',
              },
            ],
          },
        ],
      },
      {
        id: 'merchantsTransactions',
        key: securityMap.merchant.merchantTransactions,
        label: path.transactions.name,
        subSections: [
          {
            id: 'merchantsTransactionsPendingTransactions',
            key: securityMap.merchant.merchantTransactionsPendingTransactions,
            href: MERCHANT_PREFIX + path.pendingTransactions.href,
            label: path.pendingTransactions.name,
          },
          {
            id: 'merchantsTransactionsAddTransactions',
            key: securityMap.merchant.merchantTransactionsAddTransactions,
            href: MERCHANT_PREFIX + path.addTransactions.href,
            label: path.addTransactions.name,
          },
        ],
      },
    ],
  },
  {
    id: 'publishers',
    key: securityMap.admin.publishers,
    label: path.publishers.name,
    icon: path.publishers.icon,
    management: {
      href: path.publisherSearch.href,
      label: path.publisherSearch.name,
    },
    subSections: [
      {
        id: 'publishersDashboard',
        key: securityMap.publisher.publisherDashboard,
        href: PUBLISHER_PREFIX + path.dashboard.href,
        label: path.dashboard.name,
      },
      {
        id: 'publishersAccount',
        key: securityMap.publisher.publisherAccount,
        label: path.account.name,
        subSections: [
          {
            id: 'publishersAccountDetails',
            key: [
              securityMap.publisher.publisherAccountAccountDetails,
              securityMap.publisher.publisherAccountUserManagement,
            ],
            href: path.publisherSearchDetail.href,
            label: path.accountDetails.name,
          },
          {
            id: 'publishersAccountTrackingDetails',
            key: securityMap.publisher.publisherAccountTrackingDetails,
            href: PUBLISHER_PREFIX + path.trackingDetails.href,
            label: path.trackingDetails.name,
          },
          {
            id: 'publishersAccountPostbackDetails',
            key: securityMap.publisher.publisherPostbackDetails,
            href: PUBLISHER_PREFIX + path.postbackDetails.href,
            label: `Publisher ${path.postbackDetails.name}`,
          },
        ],
      },
      {
        id: 'publishersOverview',
        key: securityMap.publisher.publisherOverview,
        href: PUBLISHER_PREFIX + path.overview.href,
        label: path.overview.name,
      },
      {
        id: 'publishersMerchants',
        key: securityMap.publisher.publisherMerchants,
        label: path.merchants.name,
        subSections: [
          {
            id: 'publishersMerchantsMerchantManagement',
            key: securityMap.publisher.publisherMerchantsMerchantManagement,
            href: PUBLISHER_PREFIX + path.memberships.href,
            label: path.memberships.name,
          },
          {
            id: 'publishersMerchantsSearchPrograms',
            key: securityMap.publisher.publisherMerchantsSearchPrograms,
            href: PUBLISHER_PREFIX + path.searchProgram.href,
            label: path.searchProgram.name,
          },
        ],
      },
      {
        id: 'publishersCommissions',
        key: securityMap.publisher.publisherCommissions,
        href: PUBLISHER_PREFIX + path.activeComissions.href,
        label: path.activeComissions.name,
      },
      {
        id: 'publishersAds',
        key: securityMap.publisher.publisherAds,
        label: path.ads.name,
        subSections: [
          {
            id: 'publishersAdsAdSearch',
            key: securityMap.publisher.publisherAdsAdSearch,
            href: PUBLISHER_PREFIX + path.adSearch.href,
            label: path.adSearch.name,
          },
          {
            id: 'publishersAdsProductFeed',
            key: securityMap.publisher.publisherAdsProductFeed,
            href: PUBLISHER_PREFIX + path.productFeed.href,
            label: path.productFeed.name,
          },
        ],
      },
      {
        id: 'publishersReports',
        key: securityMap.publisher.publisherReports,
        label: path.reports.name,
        subSections: [
          {
            id: 'publishersReportsPerformanceReport',
            key: securityMap.publisher.publisherReportsPerformanceReport,
            href: PUBLISHER_PREFIX + path.performanceReport.href,
            label: path.performanceReport.name,
          },
          {
            id: 'publishersReportsTransactionMaster',
            key: securityMap.publisher.publisherReportsTransactionMaster,
            href: PUBLISHER_PREFIX + path.transactionMaster.href,
            label: path.transactionMaster.name,
          },
          {
            id: 'publishersReportsPaymentReport',
            key: securityMap.publisher.publisherReportsPaymentReport,
            href: PUBLISHER_PREFIX + path.paymentReport.href,
            label: path.paymentReport.name,
          },
        ],
      },
      {
        id: 'publishersFintelMonitor',
        key: securityMap.publisher.publisherFintelMonitor,
        label: path.fintelMonitor.name,
        subSections: [
          {
            id: 'publishersFintelMonitorMonitorLinkManager',
            key: securityMap.publisher.publisherFintelMonitorLinkManager,
            href: PUBLISHER_PREFIX + path.linkManager.href,
            label: path.linkManager.name,
          },
        ],
      },
    ],
  },
  {
    id: 'ads',
    key: securityMap.admin.ads,
    label: path.ads.name,
    icon: path.ads.icon,
    subSections: [
      {
        id: 'adsSearch',
        key: securityMap.admin.adsSearch,
        href: path.adSearch.href,
        label: path.adSearch.name,
      },
      {
        id: 'adsCampaignManagement',
        key: securityMap.admin.adsCampaignManagement,
        href: path.campaignManagement.href,
        label: path.campaignManagement.name,
      },
    ],
  },
  {
    id: 'reports',
    key: securityMap.admin.reports,
    label: path.reports.name,
    icon: path.reports.icon,
    subSections: [
      {
        id: 'reportsPerformanceReport',
        key: securityMap.admin.reportsPerformanceReport,
        href: path.performanceReport.href,
        label: path.performanceReport.name,
      },
      {
        id: 'reportsTransactionMaster',
        key: securityMap.admin.reportsTransactionMaster,
        href: path.transactionMaster.href,
        label: path.transactionMaster.name,
      },
      {
        id: 'reportsManageSavedReports',
        key: securityMap.admin.reportsManageSavedReports,
        href: path.manageSavedReports.href,
        label: path.manageSavedReports.name,
      },
    ],
  },
  {
    id: 'fintelCheck',
    key: securityMap.admin.fintelCheck,
    label: path.fintelChecks.name,
    icon: path.fintelChecks.icon,
    subSections: [
      {
        id: 'fintelCheckLinkManager',
        key: securityMap.admin.fintelCheckLinkManager,
        href: path.linkManager.href,
        label: path.linkManager.name,
      },
      {
        id: 'fintelCheckNewSiteMonitoring',
        key: securityMap.admin.fintelCheckNewSiteMonitoring,
        href: path.newSiteMonitoring.href,
        label: path.newSiteMonitoring.name,
      },
      {
        id: 'fintelCheckFintelCheckReport',
        key: securityMap.admin.fintelCheckAdminCheckReport,
        href: path.adminCheckReport.href,
        label: path.adminCheckReport.name,
      },
      {
        id: 'fintelCheckReportsMonitoringReport',
        key: securityMap.admin.fintelMonitoringRulesReport,
        href: path.monitoringRulesReport.href,
        label: 'Monitoring Report',
      },
    ],
  },
  {
    id: 'transactions',
    key: securityMap.admin.transactions,
    label: path.transactions.name,
    icon: path.transactions.icon,
    subSections: [
      {
        id: 'transactionsPendingTransactions',
        key: securityMap.admin.transactionsPendingTransactions,
        href: path.pendingTransactions.href,
        label: path.pendingTransactions.name,
      },
      {
        id: 'transactionsAddTransactions',
        key: securityMap.admin.transactionsAddTransactions,
        href: path.addTransactions.href,
        label: path.addTransactions.name,
      },
    ],
  },
  {
    id: 'payments',
    key: securityMap.admin.payments,
    label: path.payments.name,
    icon: path.payments.icon,
    subSections: [
      {
        id: 'paymentsGeneratePayments',
        key: securityMap.admin.paymentsGeneratePayments,
        href: path.generatePayment.href,
        label: path.generatePayment.name,
      },
      {
        id: 'paymentsProcessPayments',
        key: securityMap.admin.paymentsProcessPayments,
        href: path.processPayment.href,
        label: path.processPayment.name,
      },
      {
        id: 'paymentsPaymentReport',
        key: securityMap.admin.paymentsPaymentReport,
        href: path.paymentReport.href,
        label: path.paymentReport.name,
      },
      {
        id: 'paymentsCurrencyRates',
        key: securityMap.admin.paymentsCurrencyRates,
        href: path.currencyRates.href,
        label: path.currencyRates.name,
      },
      {
        id: 'paymentsManualPublisherBonuses',
        key: securityMap.admin.paymentsManualPublisherBonuses,
        href: path.manualPublisherBonuses.href,
        label: path.manualPublisherBonuses.name,
      },
      {
        id: 'paymentsAccountBalance',
        key: securityMap.admin.paymentsAccountBalance,
        href: path.merchantAccountBalanceManagement.href,
        label: path.merchantAccountBalanceManagement.name,
      },
    ],
  },
  {
    id: 'settings',
    key: securityMap.admin.settings,
    label: path.settings.name,
    icon: path.settings.icon,
    subSections: [
      {
        id: 'settingsUserManagement',
        key: securityMap.admin.settingsUserManagement,
        href: path.userManagement.href,
        label: path.userManagement.name,
      },
      {
        id: 'settingsPublisherInvites',
        key: securityMap.admin.settingsPublisherInvites,
        href: path.publisherInvites.href,
        label: path.publisherInvites.name,
      },
      {
        id: 'settingsSecurityPermissions',
        key: securityMap.admin.settingsSecurityPermissions,
        href: path.securityPermissions.href,
        label: path.securityPermissions.name,
      },
    ],
  },
  {
    id: 'messageHub',
    key: securityMap.admin.messageHub,
    href: path.messageHub.href,
    label: path.messageHub.name,
    icon: path.messageHub.icon,
    link: path.messageHub.link,
  },
];
