import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, Radio } from 'components';
import styled from 'styled-components';
import { colors, fonts } from 'styles/theme';

export const DetailedViewModal = styled(Modal)`
  width: 100%;
  & > div {
    width: 75rem;
    max-width: 75rem;
  }
`;

export const DetailedViewContent = styled.div`
  padding: 1rem;
`;

export const DetailedViewHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const DetailedViewTitle = styled.p`
  ${fonts.gotham.H24M};
  color: ${colors.color1};
`;

export const DetailedViewUrlButton = styled.a`
  color: ${colors.color2};
  ${fonts.lato.H16};
  width: 55rem;
  word-break: break-word;
  &:hover {
    color: ${colors.color1};
  }
`;

export const DetailedViewSectionTitle = styled.p`
  ${fonts.lato.H16B}
  color: ${colors.color1};
  margin-top: 1rem;
`;

export const DetailedViewSectionSubTitle = styled.p`
  ${fonts.lato.H16}
  color: ${colors.color1};
`;

export const DetailedViewCloseIcon = styled(FontAwesomeIcon)`
  display: flex;
  align-self: flex-start;
  cursor: pointer;
  font-size: 1.125rem;
  color: ${colors.color1};
  &:hover {
    color: ${colors.color2};
  }
`;

export const ContentWrapper = styled.div`
  margin-top: 1rem;
`;

export const CheckResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.313rem;
`;

export const CheckResultsTextLabel = styled.p`
  ${fonts.lato.H16R};
  color: ${colors.color1};
`;

export const CheckResultsTextValueBold = styled.p`
  ${fonts.lato.H16B};
`;

export const CheckResultsTextValue = styled.p`
  ${fonts.lato.H16};
`;

export const CheckResultsTextValueGreen = styled.div`
  color: ${colors.color29};
`;

export const CheckResultsTextValueRed = styled.div`
  color: ${colors.color31};
`;

export const TextPhrasesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TextPhrasesColumnWrapper = styled.div`
  display: flex;
  padding: 0.625rem;
  border-bottom: 0.063rem solid ${colors.color19};
  gap: 1rem;
  width: 100%;
`;

export const TextPhrasesSmallColumn = styled.div`
  width: 5vw;
`;

export const TextPhrasesMediumColumn = styled.div`
  width: 10vw;
`;

export const TextPhrasesLargeColumn = styled.div`
  width: 15vw;
`;

export const TextPhrasesExtraLargeColumn = styled.div`
  width: 45vw;
`;

export const TextPhrasesColumnLabel = styled.p`
  color: ${colors.color13};
  ${fonts.gotham.H12B};
  text-transform: uppercase;
`;

export const TextPhrasesStatus = styled.div`
  flex: 1 0 0;
`;

export const TextPhrasesText = styled.div`
  flex: 10 0 0;
  display: flex;
  text-wrap: wrap !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
  word-break: break-all !important;
`;

export const TextPhrasesValues = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
`;

export const TextPhrasesValuesWrapper = styled.div`
  display: flex;
  padding: 0.625rem;
  border-bottom: 0.063 solid ${colors.color19};
  gap: 1rem;
  width: 100%;

  &:hover {
    background-color: ${colors.color6};
  }
`;

export const FeedbackButtonWrapper = styled.div`
  display: flex;
  width: 5vw;
  align-self: flex-start;
  & > button {
    padding: 0;
    
    & > svg {
      font-size: 1rem;
    }

    &:hover {
      & > svg {
        color: ${colors.color2};
      }
  }
`;

export const FeedbackModalContent = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
`;

export const FeedbackModalTitle = styled.p`
  ${fonts.gotham.H18B};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;
export const FeedbackModalText = styled.p`
  ${fonts.lato.H16};
  color: ${colors.color1};
  margin-bottom: 1rem;
`;

export const FeedbackModalRadioWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

export const FeedbackModalRadio = styled(Radio)`
  width: max-content;
`;

export const SubmitFeedbackButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  gap: 1rem;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const RuleDetailsLabel = styled.p`
  ${fonts.lato.H16M};
  color: ${colors.color1};
`;
